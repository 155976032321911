<template>
  <b-container>
    <div class="login-page">
      <b-row class="justify-content-center">
        <b-col cols="12" lg="6" md="12" sm="12" class="mb-4 mb-lg-0">
          <div class="login-form-box h-100">
            <h4>
              <span><font-awesome-icon icon="user" /></span>{{ $t("login") }}
            </h4>
            <b-form @submit.prevent="onSubmit" v-if="show" class="loginform">
              <div class="form-group">
                <b-form-input
                  id="input-2"
                  v-model="form.email"
                  :placeholder="$t('email_address')"
                  :state="form.emailState"
                  aria-describedby="email-feedback"
                  class="form-item"
                >
                </b-form-input>
                <b-form-invalid-feedback id="email-feedback">
                  {{ form.emailError }}
                </b-form-invalid-feedback>
              </div>
              <div class="form-group password-watch">
                <b-form-input
                  v-model="form.password"
                  :type="form.passwordFieldType"
                  :placeholder="$t('password')"
                  id="text-password"
                  :state="form.passwordState"
                  aria-describedby="password-feedback"
                  class="form-item"
                >
                </b-form-input>
                <span class="show-password">
                  <b-link class="span-txt" @click="toggleShowPass">
                    {{
                      form.passwordFieldType === "password"
                        ? $t("show")
                        : $t("hide")
                    }}
                  </b-link>
                </span>
                <b-form-invalid-feedback id="password-feedback">
                  {{ form.passwordError }}
                </b-form-invalid-feedback>
              </div>
              <div class="remember-me-wrap">
                <b-form-checkbox value="me" class="pl-0">{{
                  $t("remember_me")
                }}</b-form-checkbox>
                <span class="forgot-password">
                  <b-link to="/forgot-password/" class="">
                    {{ $t("password_forgot") }}</b-link
                  >
                </span>
              </div>
              <div class="text-center">
                <b-button type="submit" class="green-btn login-btn"
                  >{{ $t("login_continue") }}
                </b-button>
              </div>
            </b-form>
          </div>
        </b-col>
        <b-col cols="12" lg="6" md="12" sm="12">
          <div class="login-form-box h-100">
            <h4>
              <span><font-awesome-icon icon="user" /></span>
              {{ $t("new_customer") }}
            </h4>
            <div class="new-customer-wrap">
              <cmsBlockSimple
                class="content"
                v-if="typeof loginPageBlock == 'object'"
                :identifier="loginPageBlock.identifier"
              />
              <b-form
                @submit.prevent="onSubmitEmail"
                v-if="show"
                class="mt-4 create-account-form"
              >
                <div class="form-group">
                  <b-form-input
                    id="input-1"
                    :placeholder="$t('email_address')"
                    class="form-item"
                    v-model="form.email2"
                    :state="form.emailState2"
                    aria-describedby="email-feedback2"
                  ></b-form-input>
                  <b-form-invalid-feedback id="email-feedback2">
                    {{ form.emailError2 }}
                  </b-form-invalid-feedback>
                </div>
                <div class="text-center mt-60">
                  <b-button type="submit" class="green-btn login-btn"
                    >{{ $t("account_create") }}
                  </b-button>
                </div>
              </b-form>
            </div>
          </div>
        </b-col>
      </b-row>
    </div>
  </b-container>
</template>
<script>
import Login from "@storefront/core/modules/user/mixins/login";
import { Logger } from "@storefront/core/lib/logger";
import { checkEmailExist } from "@storefront/core/data-resolver/user";
import cmsBlockSimple from "@/esf_utrecht_bankxl/core/components/core/BlockSimple.vue";

export default {
  name: "LoginComponent",
  mixins: [Login],
  components: {
    cmsBlockSimple,
  },
  data() {
    return {
      emailReg:
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
      form: {
        email: "",
        emailState: null,
        email2: "",
        emailState2: null,
        emailError: "",
        emailError2: "",
        emailTimer: null,
        password: "",
        passwordState: null,
        passwordError: "",
        passwordTimer: null,
        passwordFieldType: "password",
        checked: [],
      },
      show: true,
    };
  },
  computed: {
    isLoggedIn() {
      return this.$store.getters["user/getIsLoggedIn"];
    },
    loginPageBlock() {
      return this.$store.getters[`cmsBlock/getCmsBlockByIdentifier`](
        "login_page_block"
      );
    },
  },
  watch: {
    isLoggedIn() {
      if (this.$store.getters["user/getIsLoggedIn"] == true) {
        this.$router.push({ name: "account" });
      }
    },
  },
  mounted() {
    const bcrumb = { current: this.$t("login"), routes: [] };
    this.$store.commit("breadcrumbs/set", bcrumb);
  },
  methods: {
    async validateEmail() {
      if (this.form.email.length == 0) {
        this.form.emailState = false;
        this.form.emailError = this.$t("email_is_required_field");
        return false;
      }
      if (!this.emailReg.test(this.form.email)) {
        this.form.emailState = false;
        this.form.emailError = this.$t("email_is_invalid_field");
        return false;
      }

      this.form.emailState = true;
      return true;
    },
    async validateEmail2() {
      if (this.form.email2.length == 0) {
        this.form.emailState2 = false;
        this.form.emailError2 = this.$t("email_is_required_field");
        return false;
      }
      if (!this.emailReg.test(this.form.email2)) {
        this.form.emailState2 = false;
        this.form.emailError2 = this.$t("email_is_invalid_field");
        return false;
      }
      if (!(await checkEmailExist(this.form.email2))) {
        this.form.emailState2 = false;
        this.form.emailError2 = this.$t("email_already_exists");
        return false;
      } else {
        this.$router.push({
          name: "create-account",
          params: { useremail: this.form.email2 },
        });
      }
      this.form.emailState2 = true;

      return true;
    },
    async validatePassword() {
      if (this.form.password.length == 0) {
        this.form.passwordState = false;
        this.form.passwordError = this.$t("password_is_required_field");
        return false;
      }

      this.form.passwordState = true;
      return true;
    },
    async onSubmitEmail() {
      let formValid = true;
      if (this.form.emailState2 != true) {
        this.validateEmail2();
        // if (this.form.emailState2 != true) {
        formValid = false;
        //}
      } else {
        formValid = true;
        this.$router.push("/create-account/");
      }

      Logger.debug("onSubmitEmail", "email2", formValid)();
      // this.$router.push("create-account");
    },
    async onSubmit() {
      let loginOk = false;
      let formValid = true;
      if (this.form.emailState != true) {
        this.validateEmail();
        if (this.form.emailState != true) {
          formValid = false;
        }
      }
      Logger.debug("onSubmit", "email", formValid)();
      if (this.form.passwordState != true) {
        this.validatePassword();
        if (this.form.passwordState != true) {
          formValid = false;
        }
      }

      if (formValid == true) {
        loginOk = await this.$store.dispatch("user/login", {
          username: this.form.email,
          password: this.form.password,
        });

        if (loginOk == true) {
          /*  const msg = {
            type: "success",
            title: "user_login",
            text: this.$t("user_login"),
          };*/
          // this.$store.dispatch("messages/sendMessage", { message: msg });
          // this.$router.push("/");
        }
        Logger.debug("create Account", "retval", loginOk)();
      }
    },

    toggleShowPass() {
      this.form.passwordFieldType =
        this.form.passwordFieldType === "password" ? "text" : "password";
    },
  },
};
</script>
